.form-field {
  @apply flex flex-col;

  input {
    @apply w-full h-[48px] bg-blue-100 border border-secondary font-normal text-base text-blue-900 rounded outline-none;
    @apply focus:border-blue-800 px-5 py-3 transition-all;
  }

  .invalid-feedback {
    @apply hidden text-xs mt-1 ml-1;
  }

  &.full-width {
    @apply w-full;
  }

  &.is-invalid {
    @apply text-danger;

    input {
      @apply text-blue-900 border-danger;
    }

    .invalid-feedback {
      @apply block;
    }
  }
}

.country-select {
  .PhoneInput {
    @apply w-full h-full;

    .PhoneInputCountry {
      @apply px-5 py-3;
    }

    input {
      display: none;
    }

    .PhoneInputCountrySelectArrow {
      display: none;
    }

    .PhoneInputCountry {
      width: 100%;
      justify-content: space-between;
    }

    .PhoneInputCountryIcon {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: none !important;

      img {
        object-fit: cover;
      }
    }
  }
}
